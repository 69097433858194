import { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import NextLink from 'next/link';
import { Menu, Transition } from '@headlessui/react';
import { oneLine } from 'common-tags';

import { Caption, ConditionalWrapper, Icon, Link, SocialMedia } from '@/atoms';

import ArrowDownPri from '@/assets/icons/ArrowDownPri.svg';
import ExitMenu from '@/assets/icons/ExitPopUp.svg';
import GreystarLogo from '@/assets/img/img/GreystarLogo.png';
import LogoBeCasa from '@/assets/img/logo/logo-header.svg';

import type { TypeButtonVariant } from '@/atoms/button/types';
import type { FunctionComponent } from 'react';
import type {
  ChipElemet,
  TypeMobileProps,
  megaMenuElement,
  megaMenuLinksElement,
} from './types';

const transitionClasses = {
  enter: 'transform transition ease-in-out duration-500',
  enterFrom: 'translate-x-full opacity-0',
  enterTo: 'translate-x-0 opacity-100',
  leave: 'transform transition ease-in-out duration-500',
  leaveFrom: 'translate-x-0 opacity-100',
  leaveTo: 'translate-x-full opacity-0',
};

const transitionClassesChild = {
  enter: 'transform transition ease-in-out duration-0',
  enterFrom: 'translate-x-full opacity-0',
  enterTo: 'translate-x-0 opacity-100',
  leave: 'transform transition ease-in-out duration-500',
  leaveFrom: 'translate-x-0 opacity-100',
  leaveTo: 'translate-x-full opacity-0',
};

/**
 * Mobile
 */
export const Mobile: FunctionComponent<TypeMobileProps> = ({
  className = '',
  isOpen,
  funccionOnClose,
  selectedLanguage,
  languagesToSelect,
  policies,
  socialMedia,
  menuContent,
}: TypeMobileProps) => {
  const [open, setOpen] = useState(-1);
  const openHandler = (posicion: number) => {
    if (menuContent[posicion].megaMenuElement) {
      if (posicion === open) {
        setOpen(-1);
      } else {
        setOpen(posicion);
      }
    } else {
      setOpen(-1);
    }
  };

  useEffect(() => {
    const handleScroll: any = (e: any) => {
      if (isOpen) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('scroll', handleScroll, { passive: false });
    } else {
      document.body.style.overflow = 'auto';
      document.removeEventListener('scroll', handleScroll);
    }

    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('scroll', handleScroll);
    };
  }, [isOpen]);

  const { t } = useTranslation('common');

  return (
    <Transition
      unmount={false}
      {...transitionClasses}
      show={isOpen}
      className={`fixed right-0 top-0 z-[70] flex h-screen w-full flex-col bg-[#F9F1EC] transition duration-500 ease-in-out  ${className}`}
    >
      <Transition.Child
        unmount={false}
        className="max-h-screen"
        {...transitionClassesChild}
      >
        <div className="flex flex-row justify-between border-b border-primary-400 bg-[#F9F1EC] p-5">
          <ExitMenu
            heigth={32}
            width={32}
            className="cursor-pointer text-primary-400"
            onClick={funccionOnClose}
            role="button"
          />

          <LogoBeCasa className="w-28" />

          <Menu as="div" className="uppercase">
            {({ open, close }) => (
              <>
                <Menu.Button
                  disabled={languagesToSelect.length === 0}
                  className="text-body text-body--primary flex h-full w-full flex-row items-center gap-3 uppercase text-primary-400"
                >
                  {selectedLanguage}
                  {languagesToSelect.length > 0 && (
                    <ArrowDownPri
                      height={16}
                      width={16}
                      className={`transition-all duration-0  ${
                        open ? '-rotate-180' : ''
                      }`}
                    />
                  )}
                </Menu.Button>

                <Menu.Items
                  unmount={false}
                  className="absolute flex flex-col rounded-md bg-primary-500 p-1 text-primary-400 shadow-md"
                >
                  {languagesToSelect.map((child) => (
                    <Menu.Item key={child.href}>
                      {/**
                       * DON'T REMOVE THIS
                       *
                       * This div protects the Link component from the Menu.Item that add a mouse event
                       * that makes the Link component to enter in legacy mode
                       **/}
                      <div>
                        <NextLink
                          href={child.href ?? '#'}
                          passHref
                          prefetch={false}
                          locale={child.locale}
                        >
                          <Link
                            variant="primary"
                            className="rounded-md p-2 uppercase text-white hover:bg-primary-300"
                            onClick={close}
                          >
                            {child.label}
                          </Link>
                        </NextLink>
                      </div>
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </>
            )}
          </Menu>
        </div>

        <div className="max-h-[calc(100%-73px)] overflow-scroll">
          <div className="text-subtitle text-subtitle--small border-b-[1px] border-primary-600 p-5 ">
            <div className="flex flex-col gap-10">
              {menuContent.map((child, index: number) => (
                <div className="flex flex-col" key={index}>
                  <div
                    onClick={() => openHandler(index)}
                    className={oneLine`
                      flex w-full flex-row items-center justify-between
                      ${
                        open === index ? 'border-b border-primary-200 pb-1' : ''
                      }
                    `}
                  >
                    <ConditionalWrapper
                      condition={Boolean(child?.element?.src)}
                      wrapper={(children) => (
                        <NextLink
                          href={child?.element?.src ?? '#'}
                          passHref
                          prefetch={false}
                        >
                          {children}
                        </NextLink>
                      )}
                    >
                      <Link className="w-fit" variant="primary">
                        <span className="text-heading text-heading-mobile--h2 font-normal">
                          {child?.element?.label}
                        </span>
                      </Link>
                    </ConditionalWrapper>

                    {child?.megaMenuElement !== null ? (
                      <ArrowDownPri
                        height={10}
                        width={10}
                        className={oneLine`
                          text-primary-400 transition-all duration-150
                          ${open === index ? '-rotate-180' : ''}
                        `}
                      />
                    ) : (
                      <Icon
                        height={20}
                        width={20}
                        src="ArrowLink"
                        className="fill-primary-300 text-primary-200"
                      />
                    )}
                  </div>

                  {child?.megaMenuElement && (
                    <div
                      className={oneLine`
                        flex-col transition-opacity
                        ${open === index ? 'flex' : 'hidden'}
                      `}
                    >
                      {child?.megaMenuElement?.type === 'Locations'
                        ? child?.megaMenuElement.locations?.map(
                            (elemt, subIndex: number) => (
                              <LocationElement key={subIndex} {...elemt} />
                            )
                          )
                        : child?.megaMenuElement?.columns?.map(
                            (link, suIndex) => (
                              <LinksElement key={suIndex} {...link} />
                            )
                          )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {policies && (
            <div className="flex flex-col gap-6 p-5">
              {policies.map((child, index) => (
                <NextLink
                  key={index}
                  href={child.src ?? '#'}
                  passHref
                  prefetch={false}
                >
                  <Link variant="primary">
                    <span className="text-body text-body--primary font-normal">
                      {child.label}
                    </span>
                  </Link>
                </NextLink>
              ))}
            </div>
          )}

          <div className="flex w-full flex-row items-center justify-between p-5">
            <span className="text-body text-body text-body--primary font-normal text-primary-400">
              {t('menus.followUs')}
            </span>

            <SocialMedia icons={socialMedia} iconSize={25} variants="primary" />
          </div>

          <div className="relative h-[2.20rem] w-full bg-[url(/assets/img/img/FooterPatter.svg)] bg-repeat-x" />

          <div className="flex w-full flex-col items-center justify-center gap-4 overflow-auto bg-primary-600  px-4 py-8 ">
            <div className="flex flex-col items-center justify-center gap-1 text-white">
              <Caption variant="alternative">BE CASA © 2022</Caption>

              <div className="text-other  text-other--detail">
                {t('menus.rightsReserver')}
              </div>
            </div>
            <div className="relative h-5 w-[92px]">
              <Image layout="fill" objectFit="cover" src={GreystarLogo} />
            </div>
          </div>
        </div>
      </Transition.Child>
    </Transition>
  );
};

const LocationElement: FunctionComponent<megaMenuElement> = ({
  name,
  slug,
  subLocations,
}: megaMenuElement) => {
  const [open, setOpen] = useState(false);
  const openHandler = () => {
    setOpen(!open);
  };

  let numeroDeEdificios = 0;
  subLocations.forEach((subloc: any) => {
    numeroDeEdificios += subloc.buildingLength;
  });

  const { t } = useTranslation('common');
  return (
    <div className="border-b border-primary-200">
      <div
        onClick={openHandler}
        className="flex flex-row items-center justify-between p-4 pl-0"
      >
        <span className="text-subtitle text-subtitle--large text-primary-400">
          {name}
        </span>
        <div
          className={`h-3 w-3 text-primary-400 transition-all duration-150 ${
            open ? '-rotate-180' : ''
          }`}
        >
          <ArrowDownPri height={12} width={12} />
        </div>
      </div>
      <div className={`${open ? 'flex flex-col gap-6' : 'hidden'}`}>
        {subLocations.map((loca, index: number) => (
          <div key={index} className="flex flex-row gap-3 ">
            <div className="relative h-20 min-w-[140px] rounded-md">
              {loca.locationImage && (
                <Image
                  className="rounded-md"
                  objectFit="cover"
                  layout="fill"
                  src={loca.locationImage}
                />
              )}
            </div>
            <div className="flex w-full flex-col gap-2 p-3 text-primary-400">
              <NextLink href={loca.slug} passHref prefetch={false}>
                <Link variant="primary" arrow>
                  {loca.name}
                </Link>
              </NextLink>
              {loca.promotion && (
                <span className="text-subtitle text-subtitle--small">
                  {loca.promotion}
                </span>
              )}
              {loca?.label?.text && (
                <ChipElement variant={loca?.label?.variant}>
                  {loca?.label?.text}
                </ChipElement>
              )}
            </div>
          </div>
        ))}
      </div>
      <NextLink
        href={`/es/donde-estamos/${slug}` ?? '#'}
        passHref
        prefetch={false}
      >
        <Link
          className={`${open ? 'flex py-4' : 'hidden'} `}
          variant="primary"
        >{`${t('menus.linkLocation')} ${name} (${numeroDeEdificios})`}</Link>
      </NextLink>
    </div>
  );
};

const LinksElement: FunctionComponent<megaMenuLinksElement> = ({
  links,

  title,
}: megaMenuLinksElement) => {
  const [open, setOpen] = useState(false);
  const openHandler = () => {
    setOpen(!open);
  };

  return (
    <div className="border-b  border-primary-200">
      <div
        onClick={openHandler}
        className="flex flex-row items-center justify-between p-4 pl-0"
      >
        <NextLink href={title.src ?? '#'} passHref prefetch={false}>
          <Link
            variant="primary"
            className="text-subtitle text-subtitle--large text-primary-400"
          >
            {title.label}
          </Link>
        </NextLink>
        <div
          className={`h-3 w-3 text-primary-400 transition-all duration-150 ${
            open ? '-rotate-180' : ''
          }`}
        >
          {links && <ArrowDownPri height={12} width={12} />}
        </div>
      </div>
      {links && (
        <div className={`${open ? 'flex flex-col gap-2' : 'hidden'}`}>
          {links?.map((link, index: number) => (
            <div key={index} className="flex flex-row gap-3 ">
              <div className="flex w-full flex-col p-3 text-primary-400">
                <NextLink href={link.link.src ?? '#'} passHref prefetch={false}>
                  <Link variant="primary" arrow>
                    {link.link.label}
                  </Link>
                </NextLink>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const CHIP_VARIANT_MAP: Record<TypeButtonVariant, string> = {
  primary: 'bg-state-primary-default text-text-alternative ',
  secondary: 'bg-state-secondary-default text-text-alternative ',
  outline: 'border-state-primary-default text-state-primary-default ',
  alternative: 'border-white text-white ',
};

const ChipElement: FunctionComponent<ChipElemet> = ({
  children,
  variant,
}: ChipElemet) => (
  <div
    className={oneLine`text-button text-button--small flex w-auto 
  items-center justify-center gap-x-3 rounded-full border px-6 py-2 text-center
   ${CHIP_VARIANT_MAP[variant]}`}
  >
    {children}
  </div>
);

Mobile.displayName = 'Mobile';
